import React from "react"
import { Box, CircularProgress } from "@chakra-ui/core"

import MaxWidthGrid from "../../components/Layout/MaxWidthGrid"
import { useDispatch } from "react-redux"
import { BaseDispatch } from "../../redux/store"
import HeadingSection from "../../components/HeadingSection"
import PageContainer from "../../components/PageContainer"
import Body from "../../components/typography/Body"
import SEO from "../../components/boilerplate/seo"

type PedalPointsPageProps = {
  location: Location
}

const PedalPointsPage = ({ location }: PedalPointsPageProps) => {
  const dispatch = useDispatch<BaseDispatch>()
  React.useEffect(() => {
    dispatch.sidebar.setIsOpen(false)
    dispatch.user.refreshUserData()
  }, [])

  return (
    <PageContainer>
      {/* <SEO title="Pedal Points" location={location} />
      <MaxWidthGrid>
        <Box gridColumn={["1/end", null, null, null, "3/13", null, "4/12"]}>
          <HeadingSection
            heading="Pedal Points"
            subtitle="At sixthreezero, our goal is to get as many people pedaling as we possibly can. When you create an account, you are automatically enrolled in our rewards program that will earn you points towards cash vouchers for future orders. Create your Pedal Points account and see all of the ways you can earn points towards your next bicycle."
            mb="2rem"
          />
          <Body fontWeight="bold" textAlign="center" mb="4rem">
            Powered by Loyalty Lion
          </Body>
          <div data-lion-account>
            <Box textAlign="center">
              <CircularProgress isIndeterminate color="primary" />
            </Box>
          </div>
        </Box>
      </MaxWidthGrid> */}
    </PageContainer>
  )
}
export default PedalPointsPage
